.allblog {
  min-height: 100vh;
  background: #e8e9ea59;
}
.blogFilterHeading {
  color: #ff5e00;
  margin-bottom: 4px;
}
.blogFilter {
  width: 90%;
  margin: 0 auto 1rem auto;
  padding-top: 2rem;
}

.blogFilter > input {
  width: 30%;
  border-radius: 4px;
}

.allblog .searchContainer {
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allblog .searchInput {
  margin-right: 10px;
}

.blogContainer {
  width: 90%;
  margin: auto;
}
.topBlog {
  padding: 2rem 1rem;
  border-radius: 8px;
  min-height: 35vh;
  background: white;
}
.topBlogContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.topBlogContent > * {
  flex: 1 1 15rem;
  margin-right: 1rem;
}
.topBlogContent img {
  width: 50%;
  max-height: 70vh;
  object-fit: cover;
  border-radius: 10px;
}

.topBlogContentContainer {
  display: flex;
  flex-direction: column;
}
.topBlogContentContainer > div:nth-of-type(1) {
  color: gray;
}
.topBlogContentContainer > div:nth-of-type(2) {
  text-transform: capitalize;
  font-size: 26px;
}
.topBlogContentContainer > div:nth-of-type(3) {
  color: rgb(79, 79, 79);
  flex: 1;
  text-align: justify;
}
.topBlogContentContainer > div:nth-of-type(5) {
  color: #ff5e00;
}

.allOtherBlogs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.eachBlog {
  width: calc(50% - 20px);
  margin-bottom: 2rem;
  min-height: 600px;
  margin-right: 20px;
  background: white;
  border-radius: 8px;
  flex: 1 1 28rem;
}

.eachBlog img {
  display: block;
  width: 100%;
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
}
.eachBlog > div {
  min-height: 300px;
  padding: 10px 16px;
}

.eachBlog > div div:nth-of-type(1) {
  color: gray;
}
.eachBlog > div div:nth-of-type(2) {
  text-transform: capitalize;
  font-size: 26px;
}
.eachBlog > div div:nth-of-type(3) {
  color: rgb(79, 79, 79);
  flex: 1;
  width: 95%;
  text-align: justify;
}
.eachBlog > div div:nth-of-type(4) button {
  padding: 8px 12px;
  color: white;
  background: #ff5e00;
  border: none;
  overflow: none;
  margin-top: 4px;
  border-radius: 2px;
}
.eachBlog > div div:nth-of-type(5) {
  color: #ff5e00;
}
.showOnSmall {
  display: none;
}

@media (max-width: 729px) {
  .showOnSmall {
    display: inline-block;
  }
  .hideAuthorOnSmall {
    font-size: 12px;
    display: none !important;
  }
  .blogFilter > input {
    width: 90%;
  }
  .topBlogContentContainer > div:nth-of-type(1),
  .eachBlog > div:nth-of-type(1) {
    font-size: 12px;
  }
  .topBlogContentContainer > div:nth-of-type(2) {
    font-size: 16px;
    font-weight: 600;
  }
  .topBlogContentContainer > div:nth-of-type(3) {
    text-align: left;
  }
  .eachBlog > div div:nth-of-type(3) {
    text-align: left;
  }
  .eachBlog > div div:nth-of-type(2) {
    font-size: 16px;
    font-weight: 600;
  }
  .eachBlog img {
    height: 150px;
  }
  .topBlog {
    padding: 0;
  }
  .topBlogContent img {
    margin-right: 0;
    height: 150px;
  }
  .eachBlog {
    margin-right: 0;
  }
  .topBlogContentContainer {
    padding: 10px 16px;
  }

  .topBlogContentContainer > div:nth-of-type(3) p,
  .eachBlog > div div:nth-of-type(3) p {
    line-height: 20px;
    height: 60px;
    overflow: hidden;
  }
  .eachBlog,
  .eachBlog > div {
    min-height: auto;
  }
}
