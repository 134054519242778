.editHC {
}
.editHCHeading {
  text-align: center;
  padding: 1rem 0;
  font-size: 2rem;
}
.editHCContainer {
  padding: 1rem 0;
}
.editHCContainer > div:nth-of-type(1) {
  margin: 0 1.5rem;
  font-size: 18px;
  color: #ff5e00;
}
