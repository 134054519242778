.sliderBanner {
  min-height: 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f8;
}

.sliderBanner > div {
  width: 80%;
  margin: 1rem auto;
  min-height: 50vh;
  height: 50vh;
  border-radius: 10px;
  overflow: hidden;
}
.sliderGlide {
  height: 100%;
  width: 100%;
  position: relative;
}

.sliderGlide .glide__slides .glide__slide {
  position: relative;
}

.sliderGlide .glide__slides .glide__slide button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.4rem 1.2rem;

  border: none;
  outline: none;
  color: white;
  background-color: #ff5e00ba;
  font-size: 1.2rem;
  border-radius: 4px;
}
.sliderBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
