/* PrivacyPolicy.css */

.privacy-policy-container {
  margin: 10px auto;
  max-width: 900px;
  padding: 20px;
  background: #e3e3e3;
  border-radius: 10px;
}
.privacy-policy-container * {
  text-align: center;
}

.privacy-policy-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.privacy-policy-container h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.privacy-policy-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}
