@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

.searchOverlay {
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 10vh; /* Change the height to match the nav bar height */
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  z-index: 1001; /* Increase the z-index to hide other nav elements */
  transition: width 0.5s; /* Add this for animation */
}
.searchBar {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
.hideWhenSearchOpen {
  display: none;
}

.nav {
  background: #ffffff;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100000;
}

.navTop > div {
  margin: 0 1rem;
}
.navTopFollowUs {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-around;
}
.navTopFollowUs > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.navTopFollowUs > div > * {
  margin: 0 0.5rem;
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.navBar {
  display: flex;
  height: 10vh;
  width: 98%;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  position: sticky;
  z-index: 1000;
  justify-content: center;
}
#logo {
  /* margin-right: auto; */
  flex: 0 1 30rem;
  position: absolute;
  top: 53%;
  left: 3%;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  width: 200px;
}
#logo img {
  width: 100%;
  height: 100%;
}
#logo p {
  font-family: "Kaushan Script", cursive;
}
.navLinks {
  /* width: 60%; */
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* flex: 1 1 30rem; */
  height: 100%;
  margin-left: 60px;
}
.navLinks li,
.navLinks a {
  height: 100%;
  cursor: pointer;
  padding: 0 14px;

  font-size: var(--navText);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLinks a:hover,
.navLinks li:hover {
  /* background: #ff5e00; */
  color: #ff5e00;
}
.clickedActiveNav {
  color: #ff5e00;
}

.navBurgerMenu {
  display: none;
  flex-direction: column;
  width: 100%;
}
.burgerLinks {
  display: none;
}

.navBurgerMenu > span {
  width: 2rem;
  height: 0.2rem;
  margin: 0.2rem 0;
  border-radius: 2px;
  background-color: black;
}
/* 
.signInOut {
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  background: #ff5e00;
} */

/* .signInOut li {
  color: white;
} */

@media (max-width: 550px) {
  .navTop {
    display: none;
  }
}
@media (max-width: 769px) {
  #logo {
    justify-content: center;
    display: flex;
  }
  #logo img {
    width: 95%;
  }
  .navBar {
    display: flex;
    height: 7vh;
    justify-content: space-between; /* Change this */
    align-items: center; /* Add this */
  }
  .navBurgerMenu {
    display: flex;
    width: fit-content;
  }
  .navLinks {
    display: none;
  }
  #logo {
    flex: 1 1 30rem;
  }
  .burgerLinks {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translateX(200%);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: block;
    width: 50%;
    background: white;
    z-index: 100000;
  }
  .burgerLinksActive {
    transform: translateX(0);
  }
  .burgerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 50%;
    margin: 10rem 0;
  }
  .burgerNavLinks li {
    list-style: none;
    padding: 1rem 0;
    margin: 0 2rem;
    font-size: var(--navText);
  }
  #logo {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .navBurgerMenu {
    display: flex;
    z-index: 10000;
    position: absolute;
  }
  .navLinks {
    display: none;
  }
  #logo {
    flex: 1 1 30rem;
  }

  .burgerLinks {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    transform: translateX(200%);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: block;
    width: 60%;
    background: white;
    z-index: 100000;
  }
  .burgerLinksActive {
    transform: translateX(0);
  }
  .burgerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 60%;
    margin-top: 2rem;
    margin: 0;
  }
  .burgerNavLinks > * {
    border-bottom: 1px solid rgba(183, 183, 183, 0.203);
  }
  .burgerNavLinks li {
    list-style: none;
    padding: 1rem 0;
    margin: 0 2rem;
    font-size: 15px;
  }
  #logo {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .navTop {
    width: 100%;
  }
  .navTop li {
    text-align: center;
  }
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1250px) {
  .navLinks {
    width: 50%;
    height: auto;
  }
}
@media (min-width: 770px) {
  .searchIcon {
    display: none !important;
  }
}
