.incredible {
  position: relative;
  height: 100vh;
}

.incredible > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(2px);
  z-index: -1;
}

.incredible > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  height: 100%;
}

.incredible > div > div {
  flex: 1;
}

.incredible > div > div:nth-child(1) {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.incredible > div > div:nth-of-type(2) img {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
}

.incredibleContainer > div:nth-of-type(1) h2 {
  font-size: 4rem;
  text-transform: uppercase;
  color: #ff5e00;
}

.incredibleContainer > div:nth-of-type(1) h4 {
  font-size: 2rem;
  color: black;
}

.incredibleContainer > div:nth-of-type(1) h5 {
  font-size: 1rem;
  color: black;
  margin-top: 3rem;
}

.incredibleImages {
  height: 100%;
  position: relative;
}

.incredibleImages > img {
  position: absolute;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.incredibleImages img:nth-of-type(1) {
  top: 35%;
  left: 70%;
  transform: translate(-50%, -50%);
}
.incredibleImages img:nth-of-type(2) {
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.incredibleImages img:nth-of-type(3) {
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
  .incredible {
    position: relative;
    height: 100vh;
    display: none;
  }
}
