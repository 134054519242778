.footer {
  padding: 2rem 0;
  position: relative;
  z-index: 5;
  color: white;
}
.footer li {
  list-style: none;
}
.footer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 6;
}
.footer > div {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
}
.footer > div > div {
  flex: 1 1 20rem;
  z-index: 10;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.footerLeft > div:nth-of-type(1) {
  margin: 1rem 0;
}

.footerLeft > div:nth-of-type(2) {
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 1rem 0;
  position: relative;
  padding: 2rem 0;
}

.footerLeft > div:nth-of-type(2)::before {
  content: "";
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
  margin: 1rem 0;
}
.footerLeft > div:nth-of-type(2)::after {
  content: "";
  position: absolute;
  bottom: -5%;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
  margin: 1rem 0;
}

.footerLeft > div:nth-of-type(2) h3 {
  font-size: 1rem;
  text-transform: uppercase;
}
.followUs {
  display: flex;
  width: 50%;
}

.followUs * {
  color: white;
}

.followUs > div:nth-of-type(2) {
  flex: 1;
  display: flex;
  justify-content: space-around;
}
.footerRight > div {
  width: 60%;
  margin: 0.4rem auto;
  height: 100%;
  background-color: white;
}
.footerRight > div > div:nth-of-type(1) {
  padding: 0.5rem 0;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgb(0, 137, 191);
}

.footerRight form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
}

.footerRight form input,
.footerRight form textarea,
.footerRight form button {
  width: 90%;
  margin: 0.4rem auto;
}

.footerRight form button {
  border: none;
  outline: none;
  padding: 0.4rem 0;
  border: 1px solid rgb(0, 137, 191);
  color: rgb(0, 137, 191);
  background: transparent;
  width: 80%;
}

@media (max-width: 768px) {
  .footer > div {
    flex-direction: row-reverse;
  }
  .footerRight {
    order: 1;
  }
  .footerLeft {
    order: 2;
  }
  .footerRight > div {
    width: auto;
  }
  .individualTrekTopLeft > div:nth-of-type(1) span {
    margin-right: 0.8rem;
  }
}
