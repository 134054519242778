.popularTrekCard {
  margin: 1rem 3rem;
  min-width: 222px;
  min-height: 24rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background: white;
}
.popularTrekCardImage {
  width: 100%;
  height: 12rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  position: relative;
}
.popularTrekCardImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(to bottom, transparent 60%, rgb(0, 0, 0) 100%);
}
.popularTrekCardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.popularTrekCardDetails {
  height: 50%;
  border-radius: 20px;
  background-color: white;
  color: black;
  flex: 1;
  transform: translateY(-10px);
  border: 2px solid white;
}
.popularTrekCardName {
  font-size: 17px;
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 600;
  text-transform: capitalize;
}
.popularTrekCardLocation {
  padding: 0 0.8rem 0.2rem 0.8rem;
  display: flex;
  align-items: center;
  color: #ff5e00;
  font-size: 80%;
}
.popularTrekCardLocation svg {
  width: 0.8em;
  height: 0.8em;
}
.popularTrekCardDuration,
.popularTrekCardPrice {
  padding: 0.5rem 1rem;
}
.popularTrekCardPrice {
  padding: 0.5rem 1rem 0.5rem 0;
}
.popularTrekRating {
  padding: 0.1rem 1rem;
}
.popularTrekRating > * {
  font-size: 12px;
  color: grey;
}
.popularTrekRating svg {
  width: 0.9rem;
  height: 0.9rem;
}
.popularTrekRating span {
  margin-left: 0.4rem;
}
.popularTrekCardDuration {
  font-size: var(--smallText);
}
.popularTrekCardPrice {
  font-size: var(--secondaryText);
  font-weight: 600;
}
.popularTrekCardButton {
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.popularTrekCardButton button:nth-of-type(1) {
  padding: 0.4rem 0.8rem;
  background: white;
  border: 1px solid #ff5e00;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.popularTrekCardButton button:nth-of-type(1):hover {
  color: white;
  background: #ff5e00;
  border: none;
}
.popularTrekCardButton button:nth-of-type(2) {
  padding: 0.6rem 1rem;
  background: #ff5e00;
  border: 2px solid #ff5e00;
  border-radius: 10px;
  color: white;
}

@media (max-width: 1600px) {
  .popularTrekCard {
    margin: 1rem;
    min-width: 16rem;
    min-height: 24rem;
  }
}
@media (max-width: 1450px) {
  .popularTrekCard {
    margin: 1rem 3rem;
    min-width: 14rem;
    min-height: 20rem;
  }
}
@media (max-width: 1200px) {
  .popularTrekCard {
    margin: 1rem 3rem;
    min-width: 14rem;
    min-height: 22rem;
  }
  .popularTrekRating > svg {
    width: 0.8rem;
    height: 0.8rem;
  }
}
@media (max-width: 1000px) {
  .popularTrekCard {
    margin: 1rem;
    min-width: 10rem;
    min-height: 20rem;
  }
}
@media (max-width: 900px) {
  .popularTrekCard {
    flex: 1 1 20rem;
    min-height: 0rem;
    min-width: 10rem;
    height: auto;
  }
}
@media (max-width: 768px) {
  .popularTrekCardLocation {
    padding: 0.2rem 0;
    font-size: 80%;
    margin-left: -0.5rem;
  }
  .popularTrekCardLocation svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.2rem;
  }
  .popularTrekCard {
    margin: 0;
    min-width: 10rem;
    background: transparent;
    overflow: hidden;
    width: 10rem;
  }
  .popularTrekCardDetails {
    background-color: white;
    padding: 0.2rem 0.8rem;
  }
  .popularTrekCardName {
    padding: 0;
    font-weight: 500;
  }
  .popularTrekCardDuration,
  .popularTrekCardPrice {
    padding: 0;
  }
  .popularTrekRating {
    padding: 0.1rem 0;
  }
  .popularTrekCardButton button:nth-of-type(1) {
    padding: 0.4rem 0.8rem;
    background: #ff5e00;
    border: 2px solid #ff5e00;
    border-radius: 10px;
    color: white;
  }
  .popularTrekCardButton {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .popularTrekCardImage {
    border-radius: 25px;
    height: 10rem;
  }
  .popularTrekCardName {
    font-size: 14px;
    white-space: nowrap;
  }
  .popularTrekCardLocation {
    font-size: 12px;
    white-space: nowrap;
  }
  .popularTrekCardDuration,
  .popularTrekCardPrice {
    font-size: 12px;
  }
  .popularTrekRating > * {
    font-size: 10px;
  }
}
