.peacefulStay {
  min-height: 60vh;
  position: relative;
}

.peacefulStayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.peacefulStayHeadingTop {
  font-size: 2rem;
  color: #ff5e00;
  text-transform: capitalize;
  text-align: center;
  padding: 1rem 0 0 0;
  font-weight: 700;
}

.peacefulStayImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.peacefulStayContainer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 100%;
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.peacefulStayContainer > div:nth-of-type(1) {
  font-size: var(--heading);
  color: #4a4a4a;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.peacefulStayContainerImages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.peacefulStayContainerImages > div {
  flex: 1 1 20rem;
}

.peacefulStayContainerImages > div {
  width: 25rem;
  height: 25rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.peacefulStayContainerImages > div:nth-of-type(2) {
  /* margin-top: 4rem; */
}

.peacefulStayContainerImages img:hover {
  border: 2px solid #ff5e00;
}
.peacefulStayContainerImages img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.peacefulStayContainerImages > div > a div {
  margin-top: 2rem;
  background-color: #ff5e00;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  color: white;
  font-weight: 500;
}

.peacefulStayPageStartingFrom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}
.peacefulStayPageStartingFrom > div:nth-of-type(2) {
  font-size: 12px;
  text-transform: capitalize;
  color: rgb(127, 127, 127);
}
.peacefulStayPageStartingFrom > div:nth-of-type(1) {
  font-size: 28px;
  color: #ff5e00;
}

@media (max-width: 900px) {
  .peacefulStay {
    min-height: 30vh;
    position: relative;
    padding-bottom: 2rem;
  }
  .peacefulStayContainer {
    width: 90%;
    padding-bottom: 0;
  }
  .peacefulStayContainerImages > div {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .peacefulStayContainer > div:nth-of-type(1) {
    font-size: 24px;
  }
  .peacefulStayContainer > div:nth-of-type(2) {
    font-size: 14px;
  }
}
